import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrudOrders = () => import('./staff/crudOrders')
const ViewStaffCrudOrdersSellers = () => import('./staff/crudOrdersSeller')
const ViewStaffCrudOrdersCourses = () => import('./staff/crudOrdersCourses')
const ViewStaffConfiguration = () => import('./staff/configuration')

Vue.use(Router)

const router =
  [
    {
      path: '/st/orders',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'OrdersStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        }, 
        {
          path: 'products',
          name: 'OrdersStaffCrudOrders',
          component: ViewStaffCrudOrders,
          beforeEnter: Permisos.IS_PERSONAL,
        },          
        {
          path: 'sellers-products',
          name: 'OrdersStaffCrudOrdersSellers',
          component: ViewStaffCrudOrdersSellers,
          beforeEnter: Permisos.IS_PERSONAL,
        },                          
        {
          path: 'courses',
          name: 'OrdersStaffCrudOrdersCourses',
          component: ViewStaffCrudOrdersCourses,
          beforeEnter: Permisos.IS_PERSONAL,
        },          
        
        {
          path: 'configuration',
          name: 'OrdersStaffConfiguration',
          component: ViewStaffConfiguration,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
      ]
    },

    {
      path: '/cu/orders',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/orders',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
