import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

const dataConfig = JSON.parse(localStorage.getItem(window.location.origin + '_ConfigurationNotificationsPush'))

var config = {
  apiKey: dataConfig.apiKey,
  authDomain: dataConfig.authDomain,  
  projectId: dataConfig.projectId,
  storageBucket: dataConfig.storageBucket,
  messagingSenderId: dataConfig.messagingSenderId  
};

firebase.initializeApp(config);

// Retrieve Firebase Messaging object.    
const messaging = firebase.messaging();
messaging.requestPermission()
.then(function() {
  if(!isTokenSentToServer()) {        
    getRegToken();
  } else {
    console.log('La suscripción ya fue realizada.');
  }
})
.catch(function(err) {
  console.log('No se puede obtener permisos para notificar.', err);
}); 

function getRegToken(argument) {
  messaging.getToken()
    .then(function(currentToken) {
      if (currentToken) {
        saveToken(currentToken);        
        setTokenSentToServer(true);
      } else {
        console.log('No acepta los permisos de notificación.');
        setTokenSentToServer(false);
      }
    })
    .catch(function(err) {
      console.log('Error encontrado en la suscripción.', err);
      setTokenSentToServer(false);
    });
}
function setTokenSentToServer(sent) {
  window.sessionStorage.setItem(window.location.origin + '_NotificationsSentToServer', sent ? 1 : 0);
}
function isTokenSentToServer() {
  return window.sessionStorage.getItem(window.location.origin + '_NotificationsSentToServer') == 1;
}
function saveToken(currentToken) {      
  var url = ''
  if(Session.getToken()) {
    url = ConfigAPI.baseURL + "public/notifications/push/1/subscribe" + Session.getToken()
  } else {
    url = ConfigAPI.baseURL + "public/notifications/push/1/subscribe"    
  }
    
  var data = {    
    tokenDevice: currentToken,
    source: 'SYSTEM',
  }

  Vue.use(VueAxios, axios)

  const instance = axios.create();
    
  instance.post(url, data)  
  .then( (res) => {    
    console.log("Dispositivo subscripto")          
  })  
}
messaging.onMessage(function(payload) {    
  var notificationTitle = payload.data.title;
  var notificationOptions = {
    body: payload.data.body,
    icon: payload.data.icon,
    vibrate: [100],
    click_action: payload.data.url,
  };  
  var notification = new Notification(notificationTitle,notificationOptions);
  notification.onclick = function(event) {    
    event.preventDefault();
    if(payload.data.url) {
      window.open(payload.data.url , '_blank');
      notification.close();
    }
  }  
})