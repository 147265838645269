import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffPurchase = () => import('./staff/purchase')
const ViewStaffCurrentsAccounts = () => import('./staff/currentsAccounts')

Vue.use(Router)

const router =
  [
    {
      path: '/st/purchase',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'PurchaseStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'purchase',
          name: 'PurchaseStaffPurchase',
          component: ViewStaffPurchase,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'CurrentsAccounts',
          name: 'PurchaseStaffCurrentsAccounts',
          component: ViewStaffCurrentsAccounts,
          beforeEnter: Permisos.IS_PERSONAL,
        }, 
      ]
    },

    {
      path: '/cu/purchase',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/purchase',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
