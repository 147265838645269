import Vue from 'vue'
import Router from 'vue-router'

import ContainersRouter from '@/components/containers/router'
import AuthRouter from '@/components/auth/router'
import SettingsRouter from '@/components/settings/router'
import ErrorRouter from '@/components/error/router'

import Modules_SA_ModulesRouter from '@/components/modules/superadmin/modules/router'
import Modules_SA_RolesRouter from '@/components/modules/superadmin/roles/router'
import Modules_SA_ParametersRouter from '@/components/modules/superadmin/parameters/router'
import Modules_SA_PermissionsRouter from '@/components/modules/superadmin/permissions/router'

import Modules_A_StaffRouter from '@/components/modules/admin/staff/router'
import Modules_A_SettingsRouter from '@/components/modules/admin/settings/router'

import Modules_DashboardRouter from '@/components/modules/users/_dashboard/router'
import Modules_StaffRouter from '@/components/modules/users/_access/staff/router'
import Modules_CustomerRouter from '@/components/modules/users/_access/customer/router'
import Modules_SupplierRouter from '@/components/modules/users/_access/suppliers/router'
import Modules_SensorsRouter from '@/components/modules/users/sensors/router'
import Modules_NotificationsRouter from '@/components/modules/users/notifications/router'
import Modules_ProjectsRouter from '@/components/modules/users/projects/router'
import Modules_EmployeeRouter from '@/components/modules/users/employee/router'
import Modules_SellersRouter from '@/components/modules/users/sellers/router'
import Modules_BCRARouter from '@/components/modules/users/bcra/router'
import Modules_ProductsRouter from '@/components/modules/users/products/router'
import Modules_ReportsVisitRouter from '@/components/modules/users/reports-visit/router'
import Modules_ReportsPaymentRouter from '@/components/modules/users/reports-payment/router'
import Modules_ReportsNewsRouter from '@/components/modules/users/reports-news/router'
import Modules_ReportsGeneralRouter from '@/components/modules/users/reports-general/router'
import Modules_OrdersRouter from '@/components/modules/users/orders/router'
import Modules_ContentsRouter from '@/components/modules/users/contents/router'
import Modules_FormsRouter from '@/components/modules/users/forms/router'
import Modules_SalesFlowRouter from '@/components/modules/users/sales-flow/router'
import Modules_GoogleAdwordsRouter from '@/components/modules/users/google-adwords/router'
import Modules_SocialNetworksRouter from '@/components/modules/users/social-networks/router'
import Modules_EmailMarketingRouter from '@/components/modules/users/email-marketing/router'
import Modules_AccessRouter from '@/components/modules/users/access/router'
import Modules_PricesRouter from '@/components/modules/users/prices/router'
import Modules_BitlyRouter from '@/components/modules/users/bitly/router'
import Modules_WhatsappMarketingRouter from '@/components/modules/users/whatsapp-marketing/router'
import Modules_BudgetsRouter from '@/components/modules/users/budgets/router'
import Modules_SalesRouter from '@/components/modules/users/sales/router'
import Modules_PurchaseOrdersRouter from '@/components/modules/users/purchase-orders/router'
import Modules_PurchaseRouter from '@/components/modules/users/purchase/router'
import Modules_AccountingRouter from '@/components/modules/users/accounting/router'
import Modules_FinancesRouter from '@/components/modules/users/finances/router'
import Modules_CoursesRouter from '@/components/modules/users/courses/router'
import Modules_PaymentsRouter from '@/components/modules/users/payments/router'
import Modules_ServicesRouter from '@/components/modules/users/services/router'
import Modules_ReportsServicesRouter from '@/components/modules/users/reports-services/router'
import Modules_ReportsRouter from '@/components/modules/users/reports/router'
import Modules_ReportsAuditRouter from '@/components/modules/users/reports-audit/router'
import Modules_ReportsMarketingRouter from '@/components/modules/users/reports-marketing/router'
import Modules_ActivityLogRouter from '@/components/modules/users/activity-log/router'
import Modules_RemitsRouter from '@/components/modules/users/remits/router'
import Modules_CostsRouter from '@/components/modules/users/costs/router'
import Modules_ReceiptsErpRouter from '@/components/modules/users/receipts-erp/router'
import Modules_PaymentsErpRouter from '@/components/modules/users/payments-erp/router'
import Modules_DepositsRouter from '@/components/modules/users/deposits/router'
import Modules_InstancesRouter from '@/components/modules/users/instances/router'
import Modules_PurchaseRemitsRouter from '@/components/modules/users/purchase-remits/router'

Vue.use(Router)

var allRoutes = []
allRoutes = allRoutes.concat( AuthRouter,
                              SettingsRouter,                                               
                              ContainersRouter,
                              Modules_SA_ModulesRouter,
                              Modules_SA_RolesRouter,
                              Modules_SA_ParametersRouter,
                              Modules_SA_PermissionsRouter,
                              Modules_A_StaffRouter,
                              Modules_A_SettingsRouter,
                              Modules_DashboardRouter,
                              Modules_StaffRouter,
                              Modules_CustomerRouter,
                              Modules_SupplierRouter,
                              Modules_SensorsRouter,
                              Modules_NotificationsRouter,
                              Modules_ProjectsRouter,
                              Modules_EmployeeRouter,
                              Modules_SellersRouter,
                              Modules_BCRARouter,
                              Modules_ProductsRouter,
                              Modules_ReportsVisitRouter,
                              Modules_ReportsPaymentRouter,
                              Modules_ReportsNewsRouter,
                              Modules_ReportsGeneralRouter,
                              Modules_OrdersRouter,
                              Modules_ContentsRouter,
                              Modules_FormsRouter,
                              Modules_SalesFlowRouter,
                              Modules_GoogleAdwordsRouter,
                              Modules_SocialNetworksRouter,
                              Modules_EmailMarketingRouter,
                              Modules_AccessRouter,
                              Modules_PricesRouter,
                              Modules_BitlyRouter,
                              Modules_WhatsappMarketingRouter,
                              Modules_BudgetsRouter,
                              Modules_SalesRouter,
                              Modules_PurchaseOrdersRouter,
                              Modules_PurchaseRouter,
                              Modules_AccountingRouter,
                              Modules_FinancesRouter,
                              Modules_CoursesRouter,
                              Modules_PaymentsRouter,
                              Modules_ServicesRouter,
                              Modules_ReportsServicesRouter,
                              Modules_ReportsRouter,
                              Modules_ReportsAuditRouter,
                              Modules_ReportsMarketingRouter,
                              Modules_ActivityLogRouter,
                              Modules_RemitsRouter,
                              Modules_CostsRouter,
                              Modules_ReceiptsErpRouter,
                              Modules_PaymentsErpRouter,
                              Modules_DepositsRouter,
                              Modules_InstancesRouter,
                              Modules_PurchaseRemitsRouter,
                              ErrorRouter)

const routes = allRoutes

export default new Router({
  mode: 'hash',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes
})
