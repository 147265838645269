import Storage from '@/handler/storageLocal'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'

//const prod = process.env.NODE_ENV === 'production'
fetch('/pwa-status.txt')
  .then(response => response.text())
  .then(data => {
    if(data=='enable') {
      const shouldSW = 'serviceWorker' in navigator // && prod (en caso de controlar que solo se llame en produccion)
      if (shouldSW) {
        navigator.serviceWorker.register('/service-worker.js').then(() => {
          console.log("Service Worker Registered!")


          if(Storage.getValue('ConfigurationNotificationsPush')){
            require("./handler/notifications.js")  
          } else {
            Vue.use(VueAxios, axios)

            const instance = axios.create();

            instance.get(ConfigAPI.baseURL + "public/notifications/push/1/configuration")
            .then( (res) => {                                          
              Storage.setValue('ConfigurationNotificationsPush', JSON.stringify(res.data))            
              require("./handler/notifications.js")  
            })                        
          }          
        })
      }
    } else {
      console.log("PWA disable")
    }
  });

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es6/promise'
import 'core-js/es6/string'
import 'core-js/es7/array'
// import cssVars from 'css-vars-ponyfill'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import App from './app'
import router from './router'
import VueAWN from "vue-awesome-notifications"
import Loading from 'vue-loading-overlay';
import moment from 'moment'
import excel from 'vue-excel-export'
import VueLazyload from 'vue-lazyload'
import Session from '@/handler/session'
import vSelect from 'vue-select'

// Select2
Vue.component('v-select', vSelect)
// End select2
 
// todo
// cssVars()

// Color Principal
var primaryColor = 'black'
if(Session.getSession().settings) {
  primaryColor = Session.getSession().settings.color_primary
}
// End color Principal

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)
Vue.use(VueAWN,{
  maxNotifications: 1
});

Vue.use(Loading,{
    //container: this.$refs.loadingContainer,
    color: primaryColor,
    loader: 'bars',
    width: 50,
    height: 50,
    backgroundColor: '#ffffff',
    opacity: 0.3,
})
Vue.use(excel)
Vue.use(VueLazyload)

moment.locale('es')
Vue.prototype.moment = moment

/* eslint-disable no-new */
var VueApp =  new Vue({
  el: '#app',
  router,
  template: '<App/>',
  components: {
    App
  }
})
